<template>
  <div class="ButlerMgrList">
    <list
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      isMultiSelect
      ref="list"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="管家姓名" v-model="searchParams.userName" />
        <v-input label="手机号码" v-model="searchParams.mobileNum" />
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams" />
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams" />
        <v-input label="苑" placeholder="输入苑" v-model="searchParams.blockName" />
        <v-input label="幢" placeholder="输入幢" v-model="searchParams.buildingNumber" />
        <v-input label="单元" placeholder="输入单元" v-model="searchParams.unit" />
        <v-input label="室" placeholder="输入室" v-model="searchParams.room" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)" />
        <v-button text="查看" type="text" permission="search" @click="view(scope.row)" />
        <v-button text="删除" type="text" permission="delete" @click="remove(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button text="批量删除" permission="batchDelete" @click="batchRemove(scope.selectedData)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams, regionParams } from 'common/select2Params'
import { getListURL, exportListURL, deleteURL } from './api'

export default {
  name: 'ButlerMgrList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      communityParams,
      regionParams,
      searchParams: {
        userName: '',
        status: undefined,
        orgId: '',
        fromtDate: '',
        toDate: ''
      },
      headers: [
        {
          prop: 'userName',
          label: '管家姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号码'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'roomNum',
          label: '服务区域覆盖房号数'
        },
        {
          prop: 'updateTime',
          label: '更新时间'
        }
      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'butlerMgrForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'butlerMgrForm',
        query: {
          userId: row.userId,
          communityId: row.communityId
        }
      })
    },
    async batchRemove ({ data }) {
      if (data.length) {
        let isConfirm = await this.$confirm('确认删除？')
        if (isConfirm) {
          let { status } = await this.$axios.delete(deleteURL, { data })
          if (status - 0 === 100) {
            this.$refs.list.searchData()
          }
        }
      } else {
        this.$$message('请选择数据')
      }
    },
    async remove ({ communityId, userId }) {
      let isConfirm = await this.$confirm('确认删除？')
      if (isConfirm) {
        let { status } = await this.$axios.delete(deleteURL, {
          data: [{ communityId, userId }]
        })
        if (status - 0 === 100) {
          this.$refs.list.searchData()
        }
      }
    },
    view (row) {
      this.$router.push({
        name: 'butlerMgrView',
        query: {
          userId: row.userId,
          communityId: row.communityId
        }
      })
    }
  }
}
</script>
